const NO_HRO_MESSAGE = 'CRM Non-HRO scope. Avetta Pre-Qualification not required- Contractor is allowed to work on site.'

export const AVETTA_COMPLIANT = 'AVETTA_COMPLIANT'
export const AVETTA_COMPLIANT_MESSAGE = 'Avetta compliant - Supplier {{SUPPLIER_NAME}} is allowed to perform work on site.'

export const AVETTA_COMPLIANT_NO_HRO = 'AVETTA_COMPLIANT_NO_HRO'
export const AVETTA_COMPLIANT_NO_HRO_MESSAGE = NO_HRO_MESSAGE

export const AVETTA_CONDITIONALLY_COMPLIANT_NO_HRO = 'AVETTA_CONDITIONALLY_COMPLIANT_NO_HRO'
export const AVETTA_CONDITIONALLY_COMPLIANT_NO_HRO_MESSAGE = NO_HRO_MESSAGE

export const AVETTA_CONDITIONALLY_COMPLIANT_HRO = 'AVETTA_CONDITIONALLY_COMPLIANT_HRO'
export const AVETTA_CONDITIONALLY_COMPLIANT_HRO_MESSAGE = 'Avetta conditionally compliant - Supplier {{SUPPLIER_NAME}} is temporary allowed to work on site.'

export const AVETTA_NOT_COMPLIANT_NO_HRO = 'AVETTA_NOT_COMPLIANT_NO_HRO'
export const AVETTA_NOT_COMPLIANT_NO_HRO_MESSAGE = NO_HRO_MESSAGE

export const AVETTA_NOT_COMPLIANT_HRO_AND_LSE_EVENT = 'AVETTA_NOT_COMPLIANT_HRO_AND_LSE_EVENT'
export const AVETTA_NOT_COMPLIANT_HRO_AND_LSE_EVENT_MESSAGE_CONTINUE = 'Non-Compliant - Supplier {{SUPPLIER_NAME}} is not Avetta compliant. Supplier is not allowed to work on site.'

export const AVETTA_NOT_COMPLIANT_HRO_NOT_LSE_EVENT = 'AVETTA_NOT_COMPLIANT_HRO_NOT_LSE_EVENT'
export const AVETTA_NOT_COMPLIANT_HRO_NOT_LSE_EVENT_MESSAGE = 'Non-Compliant - Supplier {{SUPPLIER_NAME}} is not Avetta compliant. Supplier is not allowed to work on site.'

export const SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO = 'SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO'
export const SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO_MESSAGE = NO_HRO_MESSAGE

export const SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT = 'SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT'
export const SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT_MESSAGE = 'Supplier {{SUPPLIER_NAME}} is not found in Avetta - Exception enabled due to Sev1/Sev2 ticket.'

export const SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT = 'SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT'
export const SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT_MESSAGE = 'Supplier {{SUPPLIER_NAME}} is not found in Avetta - Not allowed to work on site.'

export const AVETTA_NOT_ACTIVE_NO_HRO = 'AVETTA_NOT_ACTIVE_NO_HRO'
export const AVETTA_NOT_ACTIVE_NO_HRO_MESSAGE = NO_HRO_MESSAGE

export const AVETTA_NOT_ACTIVE_HRO_AND_LSE_EVENT = 'AVETTA_NOT_ACTIVE_HRO_AND_LSE_EVENT'
export const AVETTA_NOT_ACTIVE_HRO_AND_LSE_EVENT_MESSAGE = 'Supplier {{SUPPLIER_NAME}} is inactive in Avetta - Not allowed to work on site.'

export const AVETTA_NOT_ACTIVE_HRO_NOT_LSE_EVENT = 'AVETTA_NOT_ACTIVE_HRO_NOT_LSE_EVENT'
export const AVETTA_NOT_ACTIVE_HRO_NOT_LSE_EVENT_MESSAGE = 'Supplier {{SUPPLIER_NAME}} is inactive in Avetta - Not allowed to work on site.'

export const AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_NO_HRO = 'AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_NO_HRO'
export const AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_NO_HRO_MESSAGE = NO_HRO_MESSAGE

export const AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_AND_LSE_EVENT = 'AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_AND_LSE_EVENT'
export const AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_AND_LSE_EVENT_MESSAGE = 'Supplier {{SUPPLIER_NAME}} is not connected to the site in Avetta - Exception enabled due to a Sev1/Sev2 ticket'

export const AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_NOT_LSE_EVENT = 'AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_NOT_LSE_EVENT'
export const AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_NOT_LSE_EVENT_MESSAGE = 'This company is not connected to your site\'s network in Avetta. Supplier {{SUPPLIER_NAME}} is not allowed to check-in.'

export const ERROR_EXCEPTION = 'ERROR_EXCEPTION'
export const ERROR_EXCEPTION_MESSAGE = 'Some unknown error occurred. Please check all the values entered and try again. If the issue persist please contact app owners at iscore-flash-dev@amazon.com.'

const SECURITY_BADGE_MESSAGE  = '➡ Obtain the appropriate badge from security.'
const ESCORT_MESSAGE = '➡ Escort the supplier/contractor to the work area.'
const NOTIFY_WHS_MONITOR_SCOPE_MESSAGE = '➡ Notify WHS to review the JHA/RAMS & monitor the scope of work.'
const CONTACT_SUPPLIER_COMPLETE_AVETTA_TASK_MESSAGE = '➡ Contact the supplier to complete open Avetta tasks.'
const SEV1_SEV2_EMERGENCY_PROCESS_MESSAGE = '➡ Follow the Sev1/Sev2 Emergency Onboarding Process.'
const NOT_ALLOWED_TO_PERFORM_WORK_MESSAGE = '➡ Notify the supplier\'s representative that the company is not allowed to work on site.'
const IDENTIFY_ALTERNATE_AND_INVITE_SIM_MESSAGE = '➡ Identify an alternate supplier; or Submit a SIM to request category approval to register supplier in Avetta.'
const IDENTIFY_ALTERNATE_COMPLIANT_SUPPLIER_MESSAGE = '➡ Identify an alternate compliant/conditionally-compliant supplier.'
const IDENTIFY_ALTERNATE_SUPPLIER_MESSAGE = '➡ Identify an alternate supplier.'
const CHECK_SUPPLIER_COMPLIANCE_ASK_REACTIVATION_AS_PER_WIKI_MESSAGE = '➡ Check the supplier compliance status in Avetta. If the supplier is compliant or conditionally compliant, request supplier re-activation. Reference the Supplier Check-In Wiki for instructions to re-activate a compliant/conditionally compliant supplier.'
const SUBMIT_REQUEST_TO_CONNECT_TO_AVETTA_MESSAGE = '➡ Submit a request to connect the contractor/subcontractor to your Avetta network.'
const ESCALATE_SITE_WHS_MESSAGE = '➡ Escalate to the site WHS Manager or identify an alternate compliant/conditionally compliant contractor.'

/**
 * These messages are based on the process map
 * defined by business attached as per ISC-ST-2017.
 * This map holds the next step messages for each of the status.
 * @type {{"[AVETTA_COMPLIANT]": string[], "[AVETTA_NOT_COMPLIANT_HRO_NOT_LSE_EVENT]": string[], "[AVETTA_NOT_COMPLIANT_HRO_AND_LSE_EVENT]": string[], "[AVETTA_NOT_COMPLIANT_NO_HRO]": string[]}}
 */
export const AvettaComplianceBasedNextStepsMessages = {
    [AVETTA_COMPLIANT] : {
        status: 'success',
        steps: [
            NOTIFY_WHS_MONITOR_SCOPE_MESSAGE,
            SECURITY_BADGE_MESSAGE,
            ESCORT_MESSAGE
        ]
    },
    [AVETTA_COMPLIANT_NO_HRO] : {
        status: 'info',
        steps:[
            NOTIFY_WHS_MONITOR_SCOPE_MESSAGE,
            SECURITY_BADGE_MESSAGE,
            ESCORT_MESSAGE
        ]
    },
    [AVETTA_CONDITIONALLY_COMPLIANT_HRO] : {
        status: 'warning',
        steps: [
            NOTIFY_WHS_MONITOR_SCOPE_MESSAGE,
            SECURITY_BADGE_MESSAGE,
            ESCORT_MESSAGE,
            CONTACT_SUPPLIER_COMPLETE_AVETTA_TASK_MESSAGE
        ]
    },
    [AVETTA_CONDITIONALLY_COMPLIANT_NO_HRO] : {
        status: 'info',
        steps: [
            NOTIFY_WHS_MONITOR_SCOPE_MESSAGE,
            SECURITY_BADGE_MESSAGE,
            ESCORT_MESSAGE
        ]
    },
    [AVETTA_NOT_COMPLIANT_NO_HRO] : {
        status: 'info',
        steps:[
            NOTIFY_WHS_MONITOR_SCOPE_MESSAGE,
            SECURITY_BADGE_MESSAGE,
            ESCORT_MESSAGE
        ]
    },
    [AVETTA_NOT_COMPLIANT_HRO_NOT_LSE_EVENT] : {
        status: 'error',
        steps: [
            NOT_ALLOWED_TO_PERFORM_WORK_MESSAGE,
            IDENTIFY_ALTERNATE_COMPLIANT_SUPPLIER_MESSAGE
        ]
    },
    [AVETTA_NOT_COMPLIANT_HRO_AND_LSE_EVENT] : {
        status: 'error',
        steps:[
            ESCALATE_SITE_WHS_MESSAGE,
        ]
    },
    [SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO] : {
        status: 'info',
        steps: [
            NOTIFY_WHS_MONITOR_SCOPE_MESSAGE,
            SECURITY_BADGE_MESSAGE,
            ESCORT_MESSAGE
        ]
    },
    [SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT] : {
        status: 'info',
        steps:[
            SEV1_SEV2_EMERGENCY_PROCESS_MESSAGE,
            NOTIFY_WHS_MONITOR_SCOPE_MESSAGE,
            SECURITY_BADGE_MESSAGE,
            ESCORT_MESSAGE
        ]
    },
    [SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT] : {
        status: 'error',
        steps:[
            NOT_ALLOWED_TO_PERFORM_WORK_MESSAGE,
            IDENTIFY_ALTERNATE_AND_INVITE_SIM_MESSAGE
        ]
    },
    [AVETTA_NOT_ACTIVE_NO_HRO] : {
        status: 'info',
        steps:[
            NOTIFY_WHS_MONITOR_SCOPE_MESSAGE,
            SECURITY_BADGE_MESSAGE,
            ESCORT_MESSAGE]
    },
    [AVETTA_NOT_ACTIVE_HRO_AND_LSE_EVENT]:{
        status: 'info',
        steps: [
            ESCALATE_SITE_WHS_MESSAGE
        ]
    },
    [AVETTA_NOT_ACTIVE_HRO_NOT_LSE_EVENT] : {
        status: 'error',
        steps: [
            NOT_ALLOWED_TO_PERFORM_WORK_MESSAGE,
            IDENTIFY_ALTERNATE_COMPLIANT_SUPPLIER_MESSAGE,
            'OR',
            CHECK_SUPPLIER_COMPLIANCE_ASK_REACTIVATION_AS_PER_WIKI_MESSAGE
        ]
    },
    [AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_NO_HRO] : {
        status: 'info',
        steps:[
            NOTIFY_WHS_MONITOR_SCOPE_MESSAGE,
            SECURITY_BADGE_MESSAGE,
            ESCORT_MESSAGE]
    },
    [AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_AND_LSE_EVENT] : {
        status: 'info',
        steps: [
            SEV1_SEV2_EMERGENCY_PROCESS_MESSAGE,
            NOTIFY_WHS_MONITOR_SCOPE_MESSAGE,
            SECURITY_BADGE_MESSAGE,
            ESCORT_MESSAGE
        ]
    },
    [AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_NOT_LSE_EVENT] : {
        status: 'info',
        steps: [
            NOT_ALLOWED_TO_PERFORM_WORK_MESSAGE,
            IDENTIFY_ALTERNATE_SUPPLIER_MESSAGE,
            SUBMIT_REQUEST_TO_CONNECT_TO_AVETTA_MESSAGE
        ]
    },
    [ERROR_EXCEPTION] : {
        status: 'error',
        steps: [
            ERROR_EXCEPTION_MESSAGE
        ]
    }
}

// Updated TODO - https://issues.amazon.com/issues/ISC-ST-2024
export const AvettaComplianceCheckMessages = {
    [AVETTA_COMPLIANT] : {
        status: 'success',
        steps:[
        AVETTA_COMPLIANT_MESSAGE
    ]},
    [AVETTA_COMPLIANT_NO_HRO] : {
        status: 'success',
        steps:[
            AVETTA_COMPLIANT_NO_HRO_MESSAGE
    ]},
    [AVETTA_CONDITIONALLY_COMPLIANT_NO_HRO]: {
        status: 'warning',
        steps:[
            AVETTA_CONDITIONALLY_COMPLIANT_NO_HRO_MESSAGE
    ]},
    [AVETTA_CONDITIONALLY_COMPLIANT_HRO]: {
        status: 'warning',
        steps:[
            AVETTA_CONDITIONALLY_COMPLIANT_HRO_MESSAGE
        ]},
    [AVETTA_NOT_COMPLIANT_NO_HRO] : {
        status: 'error',
        steps:[
        AVETTA_NOT_COMPLIANT_NO_HRO_MESSAGE
    ]},
    [AVETTA_NOT_COMPLIANT_HRO_NOT_LSE_EVENT] : {
        status: 'error',
        steps:[
        AVETTA_NOT_COMPLIANT_HRO_NOT_LSE_EVENT_MESSAGE
    ]},
    [AVETTA_NOT_COMPLIANT_HRO_AND_LSE_EVENT] : {
        status: 'error',
        steps:[
        AVETTA_NOT_COMPLIANT_HRO_AND_LSE_EVENT_MESSAGE_CONTINUE
    ]},
    [SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO] : {
        status: 'info',
        steps:[
        SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO_MESSAGE
    ]},
    [SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT]: {
        status: 'info',
        steps:[
        SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT_MESSAGE
    ]},
    [SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT]: {
        status: 'info',
        steps:[
        SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT_MESSAGE
    ]},
    [AVETTA_NOT_ACTIVE_NO_HRO]: {
        status: 'info',
        steps:[
            AVETTA_NOT_ACTIVE_NO_HRO_MESSAGE
        ]
    },
    [AVETTA_NOT_ACTIVE_HRO_AND_LSE_EVENT]: {
        status: 'info',
        steps:[
            AVETTA_NOT_ACTIVE_HRO_AND_LSE_EVENT_MESSAGE
        ]
    },
    [AVETTA_NOT_ACTIVE_HRO_NOT_LSE_EVENT]: {
        status: 'info',
        steps:[
            AVETTA_NOT_ACTIVE_HRO_NOT_LSE_EVENT_MESSAGE
        ]
    },
    [AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_NO_HRO]: {
        status: 'info',
        steps: [
            AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_NO_HRO_MESSAGE
        ]
    },
    [AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_AND_LSE_EVENT]: {
        status: 'info',
        steps: [
            AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_AND_LSE_EVENT_MESSAGE
        ]
    },
    [AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_NOT_LSE_EVENT]: {
        status: 'info',
        steps: [
            AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_NOT_LSE_EVENT_MESSAGE
        ]
    },
    [ERROR_EXCEPTION]: {
        status: 'error',
        steps: [
            ERROR_EXCEPTION_MESSAGE
        ]        
    }
}

export const AvettaComplianceMessageStatus = {
	Success: "success",
	Warning: "warning",
	Error: "error",
    Info: "info"
}